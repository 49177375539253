/* You can add global styles to this file, and also import other style files */
/*@import '@angular/material/prebuilt-themes/pink-bluegrey.css';*/
/*@import '../src/theme.scss';*/

/*html, body, md-sidenav-container, .my-content {*/
/*margin: 0;*/
/*width: 100%;*/
/*height: 100%;*/
/*}*/
@import "https://js.arcgis.com/4.22/@arcgis/core/assets/esri/themes/light/main.css";

html, body {
  height: 100%;
  width: 100%;
  margin: 0;
}

app-root, root-state {
  display: flex;
  min-height: 100vh;
  max-height: 100vh;
  flex-direction: column;
}

app-featureclasses, app-mapservices, app-geoplatform, app-dashboard,
app-login, app-changelog, app-external-servers, app-global-search-results, .list-container, .sidenav-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-height: 100%;
  min-height: 100%;
  height: 100%;
}

.mat-drawer-container {
  flex: 1;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  background-color: #fafafa !important;
}

.mat-drawer-content {
  flex: 1;
  display: flex !important;
  flex-direction: column;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  overflow: hidden !important;
}

.mat-drawer-container, .mat-table, .mat-paginator {
  background-color: #fafafa !important;
}

.filter-sidenav > mat-list > mat-list-item > .mat-list-item-content {
  justify-content: space-around !important;
  height: 60px !important;
}


mat-table {
  padding-right: 15px;
  height: 100%;
  overflow: auto;
  max-height: 100%;
  min-height: 100%;
  flex: 1 1 auto;
}


mat-table mat-row:nth-child(odd) {
  background-color: #eeeeee;
}

mat-row.row.mat-row:hover {
  background: rgba(0, 0, 0, 0.08);
}

mat-paginator {
  padding-right: 70px !important;
}

mat-toolbar.sub-header {
  background: #6ec6ff !important;
  min-height: 48px;
  max-height: 48px;
}

.sub-header mat-toolbar-row {
  height: 48px;
}

.sub-header button {
  min-width: 44px;
  padding: 0;
  margin-right: 10px;
}

.sidenav mat-list-item mat-icon {
  padding-right: 12px;
}

.header mat-toolbar-row.mat-toolbar-row {
  justify-content: space-between;
}

featureclass-details .mat-list .mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  height: unset;
}

mapservice-details .mat-list .mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  height: unset;
}

/*mapservice-details .mat-expansion-panel-header-description {*/
/*display: inline;*/
/*text-align: center;*/
/*!*flex-grow: 2;*!*/
/*!*justify-content: center;*!*/
/*!*align-items: center;*!*/
/*!*text-align: center;*!*/
/*}*/

geoplatform-details .mat-list .mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  height: unset;
}

app-global-search-results .mat-list .mat-list-item .mat-list-item-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  min-height: 48px;
  padding: 0 16px;
  position: relative;
  height: unset;
}


.details-container {
  padding-right: 15px;
}


.header-cell.checkmark > .mat-sort-header-container {
  justify-content: center;
}

.header-cell.shortname, .cell.shortname {
  min-width: 200px;
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
  flex-basis: 0;
  flex-grow: 2 !important;
}

.mat-expansion-panel-header-description {
  justify-content: space-between;
  align-items: center;
}

.mat-header-row {
  position: sticky;
  top: 0;
  background: #FAFAFA;
  z-index: 9999;
}

.mat-column-open_in_new mat-icon {
  color: lightgrey;
}

.active {
  background-color: rgba(0, 0, 0, 0.1);
}

.list-header {
  display: flex;
  min-height: 48px;
  /*flex: 1;*/
  flex-direction: row;
  justify-content: space-between;
  padding: 15px 24px 5px;
  /*padding: 15px 70px 15px 40px;*/
}

.cell {
  font-size: 13px;
  color: rgba(0, 0, 0, 0.87);
  -ms-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}


mat-row.active {
  background-color: whitesmoke;
  cursor: pointer;
}

a.mat-list-item {
  text-decoration: none;
}

